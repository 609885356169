import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Axios from "axios";
import moment from 'moment';
import $ from 'jquery';
import Loading from '../assets/img/loading.gif';
import { Eventcalendar, getJson, snackbar, toast, setOptions, Popup, Select, Dropdown, localeFr, Radio, Button, Textarea, Input, Switch, Datepicker, SegmentedGroup, SegmentedItem } from '@mobiscroll/react';
import { FormControl, Form } from 'react-bootstrap';
import { googleCalendarSync } from '@mobiscroll/calendar-integration';
import { CalendarNav, CalendarNext, CalendarPrev, Toast, Confirm } from '@mobiscroll/react';

setOptions({
    locale: localeFr,
    theme: 'ios',
    themeVariant: 'light'
});

// Websurvey Server URL
let websurveyServer = '';
if (window.location.href.indexOf("dev") > -1) {
    websurveyServer = 'prodev.websurvey.fr';
} else {
    websurveyServer = 'pro.websurvey.fr';
}

// Get selected patient parameters
const queryParams = new URLSearchParams(window.location.search);
const patientYear = queryParams.get('year');
const patientMonth = queryParams.get('month');
const patientPraticien = queryParams.get('pra');
const patientLstName = queryParams.get('lastname');
const patientFstName = queryParams.get('firstname');

const now = (patientYear && patientMonth) ? new Date(`${patientYear}-${patientMonth}`) : new Date(); // Get selected patient date

const responsivePopup = {
    medium: {
        display: 'bubble',
        width: 500,
        fullScreen: false,
        touchUi: false
    }
};

function Agenda() {

    // const { current: myView } = useRef({ agenda: { type: 'month' } });
    const buttonRef = useRef();
    const debounce = useRef();
    const startDate = useRef();
    const endDate = useRef();
    const [calendarIds, setCalendarIds] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isToastOpen, setToastOpen] = useState(false);
    const [calendarData, setCalendarData] = useState([]);
    const [confirmEvent, setConfirmEvent] = useState();
    const [confirmOldEvent, setConfirmOldEvent] = useState();
    const [isSyncConfirm, setSyncConfirm] = useState(false);
    const [primaryCalendarId, setPrimaryCalendarId] = useState();

    const handleError = useCallback((resp) => {
        setToastMessage(resp.error ? resp.error : resp.result.error.message);
        setToastOpen(true);
    }, []);

    const handleToastClose = useCallback(() => {
        setToastOpen(false);
    }, []);

    const view = useMemo(() => {
        return {
            calendar: {
                labels: 'all',
                type: 'month'
            }
        };
    }, []);

    const [tempEvent, setTempEvent] = useState([]);
    const [googleEvent, setGoogleEvent] = useState([]);
    const [myEvents, setMyEvents] = useState([]);

    const [isCalOpen, setCalOpen] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [isBtnOpen, setBtnOpen] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [eventStart, eventStartRef] = useState(null);
    const [absentStart, absentStartRef] = useState(null);
    const [dateStart, dateStartRef] = useState(null);
    const [vacationEndDate, setVacationEndDate] = useState('');
    const [start, startRef] = useState(null);
    const [end, endRef] = useState(null);
    const [popupEventAllDay, setAllDay] = useState(false);
    const [popupEventDate, setDate] = useState([]);
    const [mySelectedDate, setSelectedDate] = useState(now);
    const [Sta_CalDat, setStatus] = useState('');

    const [IdtRecWeb_Pat, setWebSurveyNum] = useState('');
    const [LstNme_Pat, setLastName] = useState('');
    const [FstNme_Pat, setFirstName] = useState('');
    const [Dob_Pat, setBirthDate] = useState('0000-00-00 00:00:00');
    const [Ema_Pat, setMail] = useState('');
    const [Tel_Pat, setPhone] = useState('');
    const [Sex_Pat, setSexe] = useState(null);
    const [HdeEvt_Cal, setHideEvent] = useState(null);
    const [Mde_Pat, setMode] = useState('');
    const [startDateCal, setStartDate] = useState('0000-00-00 00:00:00');
    const [endDateCal, setEndDate] = useState('0000-00-00 00:00:00');

    const [correspondant, setCorrespondant] = useState([]);
    const [IdtCrp1_Pat, setCorresName1] = useState();
    const [IdtCrp2_Pat, setCorresName2] = useState();

    const [showDate, setShowDate] = useState(false);
    const [showAbsent, setShowAbsent] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    const [showVacation, setShowVacation] = useState(false);

    const [operation, setOperation] = useState([]);
    const [IdtOpt_Cal, setOperationId] = useState();

    // For title
    const [Nme_Opt, setNameOpt] = useState('');
    const [Nme_Int, setNameInt] = useState('');
    const [Dur_Int, setDurationInt] = useState(0);
    const [TotDur_Int, setTotalDurationInt] = useState(0);
    const [StartEnd_Int, setStartEndInt] = useState(0);

    const [intervention, setIntervention] = useState([]);
    const [IdtInt_Cal, setInterventionId] = useState();

    const [OpeCod_Cal, setCodeOpt] = useState('');
    const [Nte_Cal, setNote] = useState('');
    const [Rmk_Cal, setRemark] = useState('');

    const [Ccm1_Cal, setCcam1] = useState('');
    const [Ccm2_Cal, setCcam2] = useState('');
    const [Dev_Cal, setDevis] = useState('');
    const [Pyt_Cal, setPayment] = useState('');
    const [ValPyt_Cal, setValidPayment] = useState(null);

    const [selected, setSelected] = useState();
    const [filteredEvents, setFilteredEvents] = useState([]);

    const Idt_Usr = localStorage.getItem('token');
    const defaultPraticien = patientPraticien ? Number(patientPraticien) : Number(localStorage.getItem("user")); // Get selected patient praticien

    const pra_nme = localStorage.getItem("pra_nme").split(',');
    const pra_val = localStorage.getItem("pra_val").split(',');
    const accessPraticien = pra_nme.map(function (item, index) {
        return { text: pra_nme[index], value: Number(pra_val[index]) };
    });

    // For search bar
    const [dataTable, setDataTable] = useState([]);
    const [search, setSearch] = useState('');

    const searchData = (e) => {
        setSearch(e.target.value);
        Axios.get(`/api/ajax.php?ajax=getPatientData&searchBy=${e.target.value}`).then((res) => {
            setDataTable(res.data);
        });
    };

    const onPageLoading = useCallback((event) => {
        const year = sessionStorage.getItem("Ref_Date_Year") ? sessionStorage.getItem("Ref_Date_Year") : event.month.getFullYear();
        const month = sessionStorage.getItem("Ref_Date_Month") ? sessionStorage.getItem("Ref_Date_Month") : event.month.getMonth() + 1;

        // Hide events depends on User
        const usrReePra = localStorage.getItem("hideEvent");
        const usrDefPra = localStorage.getItem("user");

        clearTimeout(debounce.current);
        startDate.current = event.viewStart;
        endDate.current = event.viewEnd;
        setLoading(true);

        debounce.current = setTimeout(() => {

        googleCalendarSync
            .getEvents(calendarIds, startDate.current, endDate.current)
            .then((data) => {
                getJson(`/api/ajax.php?ajax=getAgenda&year=${year}&month=${month}&usrReePra=${usrReePra}&usrDefPra=${usrDefPra}&idtUsr=${Idt_Usr}`, (result) => {
                    for (let i = 0; i < result.length; i++) {
                        data.push({
                            id: result[i].Idt_CalDat,
                            Idt_Cal: result[i].Idt_Cal,
                            Idt_CalDat: result[i].Idt_CalDat,
                            IdtCal_CalDat: result[i].IdtCal_CalDat,
                            IdtPat_Cal: result[i].IdtPat_Cal,
                            color: result[i].color,
                            start: result[i].start,
                            end: result[i].end,
                            title: result[i].title,
                            Sta_CalDat: result[i].Sta_CalDat,
                            IdtRecWeb_Pat: result[i].IdtRecWeb_Pat,
                            LstNme_Pat: result[i].LstNme_Pat,
                            FstNme_Pat: result[i].FstNme_Pat,
                            Dob_Pat: result[i].Dob_Pat,
                            Ema_Pat: result[i].Ema_Pat,
                            Tel_Pat: String(result[i].Tel_Pat).startsWith('0') ? result[i].Tel_Pat : '0' + result[i].Tel_Pat,
                            Sex_Pat: result[i].Sex_Pat,
                            HdeEvt_Cal: result[i].HdeEvt_Cal,
                            Mde_Pat: result[i].Mde_Pat,
                            IdtCrp1_Pat: result[i].IdtCrp1_Pat,
                            IdtCrp2_Pat: result[i].IdtCrp2_Pat,
                            IdtPra_Cal: result[i].IdtPra_Cal,
                            IdtOpt_Cal: result[i].IdtOpt_Cal,
                            IdtInt_Cal: result[i].IdtInt_Cal,
                            OpeCod_Cal: result[i].OpeCod_Cal,
                            Nte_Cal: result[i].Nte_Cal,
                            Rmk_Cal: result[i].Rmk_Cal,
                            Ccm1_Cal: result[i].Ccm1_Cal,
                            Ccm2_Cal: result[i].Ccm2_Cal,
                            Dev_Cal: result[i].Dev_Cal,
                            Pyt_Cal: result[i].Pyt_Cal,
                            ValPyt_Cal: result[i].ValPyt_Cal,
                            Nme_Opt: result[i].Nme_Opt,
                            Nme_Int: result[i].Nme_Int,
                            startDateCal: result[i].startDateCal,
                            endDateCal: result[i].endDateCal
                        });
                    }
                
                    setLoading(false);
                    setMyEvents(data);
                    selected ? filterEvents(data, selected) : filterEvents(data, defaultPraticien);

                    toast({ message: 'New events loaded' });
                });

            })
            
            .catch(handleError);
        }, 200);

    }, [selected, defaultPraticien, calendarIds, handleError]);

    // const toggleCalendar = useCallback(
    //     (ev) => {
    //         const checked = ev.target.checked;
    //         const calendarId = ev.target.value;

    //         const year = sessionStorage.getItem("Ref_Date_Year") ? sessionStorage.getItem("Ref_Date_Year") : ev.month.getFullYear();
    //         const month = sessionStorage.getItem("Ref_Date_Month") ? sessionStorage.getItem("Ref_Date_Month") : ev.month.getMonth() + 1;

    //         // Hide events depends on User
    //         const usrReePra = localStorage.getItem("hideEvent");
    //         const usrDefPra = localStorage.getItem("user");

    //         calendarData[calendarId].checked = checked;
    //         if (checked) {
    //             setLoading(true);
    //             setCalendarIds((calIds) => [...calIds, calendarId]);
    //             googleCalendarSync
    //                 .getEvents([calendarId], startDate.current, endDate.current)
    //                 .then((data) => {
    //                     getJson(`/api/ajax.php?ajax=getAgenda&year=${year}&month=${month}&usrReePra=${usrReePra}&usrDefPra=${usrDefPra}&idtUsr=${Idt_Usr}`, (result) => {
    //                         for (let i = 0; i < result.length; i++) {
    //                             data.push({
    //                                 id: result[i].Idt_CalDat,
    //                                 Idt_Cal: result[i].Idt_Cal,
    //                                 Idt_CalDat: result[i].Idt_CalDat,
    //                                 IdtCal_CalDat: result[i].IdtCal_CalDat,
    //                                 IdtPat_Cal: result[i].IdtPat_Cal,
    //                                 color: result[i].color,
    //                                 start: result[i].start,
    //                                 end: result[i].end,
    //                                 title: result[i].title,
    //                                 Sta_CalDat: result[i].Sta_CalDat,
    //                                 IdtRecWeb_Pat: result[i].IdtRecWeb_Pat,
    //                                 LstNme_Pat: result[i].LstNme_Pat,
    //                                 FstNme_Pat: result[i].FstNme_Pat,
    //                                 Dob_Pat: result[i].Dob_Pat,
    //                                 Ema_Pat: result[i].Ema_Pat,
    //                                 Tel_Pat: String(result[i].Tel_Pat).startsWith('0') ? result[i].Tel_Pat : '0' + result[i].Tel_Pat,
    //                                 Sex_Pat: result[i].Sex_Pat,
    //                                 HdeEvt_Cal: result[i].HdeEvt_Cal,
    //                                 Mde_Pat: result[i].Mde_Pat,
    //                                 IdtCrp1_Pat: result[i].IdtCrp1_Pat,
    //                                 IdtCrp2_Pat: result[i].IdtCrp2_Pat,
    //                                 IdtPra_Cal: result[i].IdtPra_Cal,
    //                                 IdtOpt_Cal: result[i].IdtOpt_Cal,
    //                                 IdtInt_Cal: result[i].IdtInt_Cal,
    //                                 OpeCod_Cal: result[i].OpeCod_Cal,
    //                                 Nte_Cal: result[i].Nte_Cal,
    //                                 Rmk_Cal: result[i].Rmk_Cal,
    //                                 Ccm1_Cal: result[i].Ccm1_Cal,
    //                                 Ccm2_Cal: result[i].Ccm2_Cal,
    //                                 Dev_Cal: result[i].Dev_Cal,
    //                                 Pyt_Cal: result[i].Pyt_Cal,
    //                                 ValPyt_Cal: result[i].ValPyt_Cal,
    //                                 Nme_Opt: result[i].Nme_Opt,
    //                                 Nme_Int: result[i].Nme_Int,
    //                                 startDateCal: result[i].startDateCal,
    //                                 endDateCal: result[i].endDateCal
    //                             });
    //                         }
    //                         setMyEvents((oldEvents) => [...oldEvents, ...data]);
    //                         setLoading(false);
    //                         selected ? filterEvents(data, selected) : filterEvents(data, defaultPraticien);
    //                     });
    //                 })
    //                 .catch(handleError);
    //         } else {
    //             setCalendarIds((calIds) => calIds.filter((item) => item !== calendarId));
    //             setMyEvents((oldEvents) => oldEvents.filter((item) => item.googleCalendarId !== calendarId));
    //         }
    //     },
    //     [calendarData, handleError],
    // );

    const openPopup = useCallback(() => {
        setAnchor(buttonRef.current.nativeElement);
        setCalOpen(true);
    }, []);

    const navigate = useCallback(() => {
        setSelectedDate(new Date());
    }, []);

    const signIn = useCallback(() => {
        googleCalendarSync.signIn().catch(handleError);
    }, [handleError]);

    const signOut = useCallback(() => {
        googleCalendarSync.signOut().catch(handleError);
    }, [handleError]);

    const addOldEvents = useCallback(() => {
        if (googleCalendarSync.isSignedIn()) {
            googleCalendarSync
                .getCalendars()
                .then((calendars) => {
                    calendars.sort((c) => (c.primary ? -1 : 1));
                    const primaryCalId = calendars[0].id;
                    
                    return googleCalendarSync.getEvents(
                        [primaryCalId],
                        startDate.current,
                        endDate.current
                    );
                })
            .then((events) => {
                setGoogleEvent(events);
                setSyncConfirm(true)
            })
        }
    }, []);

    const customHeader = useCallback(
        () => (
            <>
                <CalendarNav />
                <div className={'mds-loader' + (isLoading ? ' mds-loader-visible' : '')}></div>
                <div className="mbsc-flex mbsc-flex-1-0 mbsc-justify-content-end">
                    {isLoggedIn ? (
                        <>
                            <Button onClick={addOldEvents}>Synchroniser le mois en cours</Button>
                            <Button ref={buttonRef} onClick={openPopup}>Mon agenda</Button>
                        </>
                    ) : (
                        <Button onClick={signIn}>Sync mon agenda Google</Button>
                    )}
                    <Button onClick={navigate}>Aujourd'hui</Button>
                </div>
                <CalendarPrev />
                <CalendarNext />
            </>
        ),
        [isLoading, isLoggedIn, navigate, openPopup, signIn, addOldEvents],
    );

    const filterEvents = (events, selected) => {
        let ev = [];
        for (let i = 0; i < events.length; ++i) {
            const item = events[i];
            if (item.IdtPra_Cal === selected) {
                ev.push(item);
            }
        }
        setFilteredEvents(ev);
    }

    const filter = (ev) => {
        const value = ev.value;
        setSelected(value);
        filterEvents(myEvents, value);
    }

    const [patFstName, setPatFstName] = useState('');
    const [patLstName, setPatLstName] = useState('');
    
    useEffect(() => {
        if (sessionStorage.getItem("reloading") === 'true') {
            let Ref_Date = sessionStorage.getItem("Ref_Date")
            setPatFstName(sessionStorage.getItem("FstNme_Pat"))
            setPatLstName(sessionStorage.getItem("LstNme_Pat"))
            setSelectedDate(moment(Ref_Date).format("YYYY-MM-DD 00:00:00"));
            sessionStorage.removeItem("Ref_Date");
            sessionStorage.removeItem("Ref_Date_Year");
            sessionStorage.removeItem("Ref_Date_Month");
            sessionStorage.removeItem("reloading");
        }
    });

    useEffect(() => {
        getJson('/api/ajax.php?ajax=getCorrespondant', (correspondant) => {
            setCorrespondant(correspondant);
        });
    }, []);

    useEffect(() => {
        getJson('/api/ajax.php?ajax=getOperation', (operation) => {
            setOperation(operation);
        });
    }, []);

    useEffect(() => {
        getJson('/api/ajax.php?ajax=getIntervention', (intervention) => {
            setIntervention(intervention);
        });
    }, []);

    // Popup event buttons
    let absentEvent = $('.mbsc-calendar-label-text:contains("Absent")');
    let eventEvent = $('.mbsc-calendar-label-text:contains("Évènement")');
    let vacationEvent = $('.mbsc-calendar-label-text:contains("Vacances")');

    const eventData = {
        connectedUserId: Idt_Usr,
        Idt_Cal: tempEvent.Idt_Cal,
        Idt_CalDat: tempEvent.Idt_CalDat,
        IdtPat_Cal: tempEvent.IdtPat_Cal,
        IdtRecWeb_Pat: IdtRecWeb_Pat,
        LstNme_Pat: LstNme_Pat,
        FstNme_Pat: FstNme_Pat,
        Sex_Pat: Sex_Pat,
        Dob_Pat: Dob_Pat,
        Ema_Pat: Ema_Pat,
        Tel_Pat: Tel_Pat,
        IdtCrp1_Pat: IdtCrp1_Pat,
        IdtCrp2_Pat: IdtCrp2_Pat,
        IdtUsr_Cal: Idt_Usr,
        IdtUsr_Pat: Idt_Usr,
        Mde_Pat: Mde_Pat,
        IdtOpt_Cal: IdtOpt_Cal,
        IdtInt_Cal: IdtInt_Cal,
        OpeCod_Cal: OpeCod_Cal,
        Nte_Cal: Nte_Cal,
        Rmk_Cal: Rmk_Cal,
        Ccm1_Cal: Ccm1_Cal,
        Ccm2_Cal: Ccm2_Cal,
        Dev_Cal: Dev_Cal,
        Pyt_Cal: Pyt_Cal,
        ValPyt_Cal: ValPyt_Cal,
        startDateCal: startDateCal,
        endDateCal: endDateCal,
        IdtPra_Cal: selected ? selected : defaultPraticien,
        color: tempEvent.color,
    }

    let searchDate = moment(mySelectedDate).format("YYYY-MM-DD 00:00:00");

    // GET TOTAL INTERVENTION DURATION
    Axios.get(`/api/ajax.php?ajax=getTotalIntervention&searchDate=${searchDate}`).then((res) => {
        if (res.data.length > 0) {
            setTotalDurationInt(res.data[0].TotalDuration)
        }
    })

    // GET TOTAL START & END DURATION
    Axios.get(`/api/ajax.php?ajax=getStartEndDate&searchDate=${searchDate}`).then((res) => {
        if (res.data.length > 0) {
            setStartEndInt(res.data[0].timeDiff)
        }
    })

    const saveEvent = useCallback(() => {

        /* ************************************ VALIDATIONS ************************************** */
        let errorMessage = '';
        if ($('.eventButtonPopup').length === 0 && (
            $('#operationId').val() == 553 ||
            $('#operationId').val() == 550 ||
            $('#operationId').val() == 545 ||
            $('#operationId').val() == 659 ||
            $('#operationId').val() == 660 ||
            $('#operationId').val() == 664 ||
            $('#operationId').val() == 564
        )) {
            if ($('input[name=LstNme_Pat]').val() === '') {
                errorMessage += 'Veuillez saisir le nom du patient\n';
            }
            if ($('input[name=FstNme_Pat]').val() === '') {
                errorMessage += 'Veuillez saisir le prénom du patient\n';
            }
            if ($('input[name=Ema_Pat]').val() === '') {
                errorMessage += 'Veuillez saisir l\'email du patient\n';
            }
            if ($('input[name=Tel_Pat]').val() === '') {
                errorMessage += 'Veuillez saisir le téléphone mobile du patient\n';
            }
            if ($('input[name=Mde_Pat]:checked').length === 0) {
                errorMessage += 'Veuillez saisir un mode ambulatoire ou hospitalisation';
            }
        } else {
            if ($('.eventButtonPopup').length === 0 && $('input[name=Mde_Pat]:checked').length === 0) {
                errorMessage += 'Veuillez saisir un mode ambulatoire ou hospitalisation';
            }
        }

        if (errorMessage !== '') {
            alert(errorMessage);
        }
        else {
            /* ************************************ UPDATE ************************************** */
            if (isEdit) {

                // DATE CHANGE
                if (showDate) {
                    const event = {
                        IdtSta_Cal: 5,
                        Sta_CalDat: "postponed",
                        title:
                            Mde_Pat == 'hospitalisation' ?
                                `<img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`
                                : `<img src=https://weborganizer.fr/img/ambulatoire.png />
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`,
                        DatCal1_CalDat: moment(popupEventDate[0]).format("YYYY-MM-DD 00:00:00"),
                        DatCal2_CalDat: "0000-00-00 00:00:00",
                        start: moment(popupEventDate[0]).format("YYYY-MM-DDT00:00:00"),
                        end: null,
                    }
                    const ev = { ...event, ...eventData };

                    const ppDate = window.confirm('Voulez-vous réporté ce rendez-vous ?');
                    if (ppDate === true) {
                        Axios.put(`/api/function.php/${tempEvent.Idt_CalDat}?ajax=updateState`, ev).then((res) => {
                            setTimeout(() => {
                                const index = filteredEvents.findIndex(x => x.Idt_CalDat === tempEvent.Idt_CalDat);
                                const newEventList = [...filteredEvents];

                                if (googleCalendarSync.isSignedIn()) {
                                    setSyncConfirm(true);
                                    setToastMessage('Event updated on "' + calendarData[primaryCalendarId].name + '" calendar');
                                } else {
                                    newEventList.splice(index, 1, ev);
                                    setFilteredEvents(newEventList);
                                }
                            }, 500);
                        });
                    }
                }

                // ABSENT
                else if ((showAbsent || absentEvent) && ($(".eventEvent, .vacationEvent").is(":hidden")) && $(".absentEvent").is(":visible")) {
                    const event = {
                        IdtSta_Cal: 2,
                        Sta_CalDat: "confirmed",
                        title: "Absent : " + Rmk_Cal,
                        DatCal1_CalDat: moment(popupEventDate[0]).format("YYYY-MM-DD 00:00:00"),
                        DatCal2_CalDat: "0000-00-00 00:00:00",
                        start: moment(popupEventDate[0]).format("YYYY-MM-DDT00:00:00"),
                        end: null,
                    }
                    const ev = { ...event, ...eventData };

                    Axios.put(`/api/function.php/${tempEvent.Idt_CalDat}?ajax=updateState`, ev).then((res) => {
                        setTimeout(() => {
                            const index = filteredEvents.findIndex(x => x.Idt_CalDat === tempEvent.Idt_CalDat);
                            const newEventList = [...filteredEvents];

                            if (googleCalendarSync.isSignedIn()) {
                                setSyncConfirm(true);
                                setToastMessage('Event updated on "' + calendarData[primaryCalendarId].name + '" calendar');
                            } else {
                                newEventList.splice(index, 1, ev);
                                setFilteredEvents(newEventList);
                            }
                        }, 500);
                    });
                }

                // EVENT
                else if ((showEvent || eventEvent) && ($(".absentEvent, .vacationEvent").is(":hidden")) && $(".eventEvent").is(":visible")) {
                    const event = {
                        IdtSta_Cal: 3,
                        Sta_CalDat: "confirmed",
                        HdeEvt_Cal: HdeEvt_Cal,
                        IdtReePra_Usr: selected ? selected : defaultPraticien,
                        title: "Évènement : " + Rmk_Cal,
                        DatCal1_CalDat: moment(popupEventDate[0]).format("YYYY-MM-DD 00:00:00"),
                        DatCal2_CalDat: "0000-00-00 00:00:00",
                        start: moment(popupEventDate[0]).format("YYYY-MM-DDT00:00:00"),
                        end: null,
                    }
                    const ev = { ...event, ...eventData };

                    Axios.put(`/api/function.php/${tempEvent.Idt_CalDat}?ajax=updateState`, ev).then((res) => {
                        setTimeout(() => {
                            const index = filteredEvents.findIndex(x => x.Idt_CalDat === tempEvent.Idt_CalDat);
                            const newEventList = [...filteredEvents];

                            if (googleCalendarSync.isSignedIn()) {
                                setSyncConfirm(true);
                                setToastMessage('Event updated on "' + calendarData[primaryCalendarId].name + '" calendar');
                            } else {
                                newEventList.splice(index, 1, ev);
                                setFilteredEvents(newEventList);
                            }
                        }, 500);
                    });
                }

                // VACATION
                else if ((showVacation || vacationEvent) && ($(".absentEvent, .eventEvent").is(":hidden")) && $(".vacationEvent").is(":visible")) {
                    const event = {
                        IdtSta_Cal: 4,
                        Sta_CalDat: "confirmed",
                        title: "Vacances : " + Rmk_Cal,
                        DatCal1_CalDat: moment(popupEventDate[0]).format("YYYY-MM-DD 00:00:00"),
                        DatCal2_CalDat: popupEventDate[1] ? moment(popupEventDate[1]).format("YYYY-MM-DD 00:00:00") : moment(popupEventDate[0]).format("YYYY-MM-DD 00:00:00"),
                        start: moment(popupEventDate[0]).format("YYYY-MM-DDT00:00:00Z"),
                        end: popupEventDate[1] ? moment(popupEventDate[1]).format("YYYY-MM-DDT00:00:00Z") : moment(popupEventDate[0]).format("YYYY-MM-DDT00:00:00Z"),
                    }
                    const ev = { ...event, ...eventData };

                    Axios.put(`/api/function.php/${tempEvent.Idt_CalDat}?ajax=updateState`, ev).then((res) => {
                        setTimeout(() => {
                            const index = filteredEvents.findIndex(x => x.Idt_CalDat === tempEvent.Idt_CalDat);
                            const newEventList = [...filteredEvents];
                            
                            if (googleCalendarSync.isSignedIn()) {
                                setSyncConfirm(true);
                                setToastMessage('Event updated on "' + calendarData[primaryCalendarId].name + '" calendar');
                            } else {
                                newEventList.splice(index, 1, ev);
                                setFilteredEvents(newEventList);
                            }

                        }, 500);
                    });
                }

                // GENERAL UPDATE
                else {
                    const event = {
                        Sta_CalDat: Sta_CalDat,
                        DatCal1_CalDat: moment(mySelectedDate).format("YYYY-MM-DD 00:00:00"),
                        DatCal2_CalDat: moment(mySelectedDate).format("YYYY-MM-DD 00:00:00"),
                        start: startDateCal, 
                        end: endDateCal
                    }

                    const ev = { ...eventData, ...event };

                    if (Sta_CalDat == "cancelled") {
                        const result = window.confirm('Voulez-vous supprimer cette intervention ? \n(il est possible de déplacer une intervention sans supprimer la fiche patient)');
                        if (result === true) {
                            let cancelRemark = window.prompt("Description (obligatoire) :");
                            while (true) {
                                if (cancelRemark == null) {
                                    break;
                                }
                                else if (cancelRemark == "") {
                                    cancelRemark = window.prompt("Description (obligatoire) :");
                                } else {
                                    const cancelDescription = {
                                        Rmk_Cal: cancelRemark
                                    }
                                    const cancelEvent = { ...ev, ...cancelDescription }

                                    Axios.put(`/api/function.php/${tempEvent.Idt_CalDat}?ajax=updateAgenda`, cancelEvent).then((res) => {
                                        setTimeout(() => {
                                            Axios.get(`/api/recordNum.php?ajax=isPatientAllreadyExistInWebsurvey&lname=${LstNme_Pat}&fname=${FstNme_Pat}&optId=${IdtOpt_Cal}&intId=${IdtInt_Cal}`).then((res) => {
                                                const rec = {
                                                    title:
                                                        `Annulé
                                                        <span class=eventDescription>${cancelRemark ? cancelRemark : Rmk_Cal}</span>
                                                        <span class=cancelledEvent>
                                                            <b> ${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>
                                                        </span>`
                                                }
                                                const record = { ...cancelEvent, ...rec };

                                                setTimeout(() => {
                                                    const index = filteredEvents.findIndex(x => x.Idt_CalDat === tempEvent.Idt_CalDat);
                                                    const newEventList = [...filteredEvents];
                                                    if (googleCalendarSync.isSignedIn()) {
                                                        setSyncConfirm(true);
                                                    } else {
                                                        newEventList.splice(index, 1, record);
                                                        setFilteredEvents(newEventList);
                                                    }
                                                }, 1000);
                                            })
                                        }, 500)
                                    });
                                    break;
                                }
                            }
                        }
                    }
                    else {
                    // else if (Sta_CalDat === 'postponed' || Sta_CalDat === 'confirmed') {
                        Axios.put(`/api/function.php/${tempEvent.Idt_CalDat}?ajax=updateAgenda`, ev).then((res) => {
                            setTimeout(() => {
                                Axios.get(`/api/recordNum.php?ajax=isPatientAllreadyExistInWebsurvey&lname=${LstNme_Pat}&fname=${FstNme_Pat}&optId=${IdtOpt_Cal}&intId=${IdtInt_Cal}`).then((res) => {
                                    const rec = {
                                        title:
                                            Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 553 ?
                                                `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=3&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 550 ?
                                                    `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=2&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                    : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 545 && IdtInt_Cal == 2641 ?
                                                        `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=97&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                        : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 659 ?
                                                            `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=111&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                            : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 660 ?
                                                                `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=121&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 664 ?
                                                                    `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=127&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                    : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 564 ?
                                                                        `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=24&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                        : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 553 ?
                                                                            `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=3&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                            : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 550 ?
                                                                                `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=2&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 545 && IdtInt_Cal == 2641 ?
                                                                                    `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=97&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                    : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 659 ?
                                                                                        `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=111&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                        : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 660 ?
                                                                                            `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=121&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                            : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 664 ?
                                                                                                `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=127&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                                : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 564 ?
                                                                                                    `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=24&searchIdt_Rec=${res.data}>
                                        <img src=https://weborganizer.fr/img/ws.png />
                                    </a>
                                    <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                                    : Mde_Pat == 'hospitalisation' ?
                                                                                                        ` <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                                        : ` <img src=https://weborganizer.fr/img/ambulatoire.png />
                                    <span class=interventionTime>${Dur_Int}</span>
                                    <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`
                                    }
                                    const record = { ...ev, ...rec };

                                    setTimeout(() => {
                                        const index = filteredEvents.findIndex(x => x.Idt_CalDat === tempEvent.Idt_CalDat);
                                        const newEventList = [...filteredEvents];
                                        
                                        if (googleCalendarSync.isSignedIn()) {
                                            setSyncConfirm(true);
                                            setToastMessage('Event updated on "' + calendarData[primaryCalendarId].name + '" calendar');
                                        } else {
                                            newEventList.splice(index, 1, record);
                                            setFilteredEvents(newEventList);
                                        }
                                    }, 500);
                                })
                            }, 500)
                        });
                    }
                }
            }

            /* ************************************ INSERT ************************************** */
            else {
                let startDateZone = moment(mySelectedDate).format("YYYY-MM-DDT07:00:00Z");
                let startTime = '';
                let endTime = '';
                if (StartEnd_Int > 0) {
                    startTime = moment(startDateZone).add(StartEnd_Int, 'minutes');
                } else {
                    startTime = moment(mySelectedDate).format("YYYY-MM-DDT07:00:00Z");
                }
                if (Dur_Int > 0) {
                    endTime = moment(startTime).add(Dur_Int, 'minutes');
                } else {
                    endTime = moment(startTime).add(30, 'minutes');
                }

                // ABSENT
                if (showAbsent) {
                    const event = {
                        IdtSta_Cal: 2,
                        Sta_CalDat: Sta_CalDat,
                        title: "Absent : " + Rmk_Cal,
                        DatCal1_CalDat: moment(popupEventDate[0]).format("YYYY-MM-DD 00:00:00"),
                        DatCal2_CalDat: "0000-00-00 00:00:00",
                        start: startTime,
                        end: endTime,
                    }

                    const ev = { ...event, ...eventData };

                    Axios.post("/api/function.php?ajax=insertState", ev).then((res) => {
                        setFilteredEvents([...filteredEvents, ev]);
                    });
                    
                    // GOOGLE AGENDA
                    if (googleCalendarSync.isSignedIn()) {
                        googleCalendarSync
                            .addEvent(primaryCalendarId, ev)
                            .then((newEvent) => {
                                newEvent.color = ev.color;
                                setMyEvents((oldEvents) => [...oldEvents, newEvent]);
                                setToastMessage('Event created in "' + calendarData[primaryCalendarId].name + '" calendar');
                                setToastOpen(true);
                            })
                            .catch((error) => {
                                setMyEvents((oldEvents) => [...oldEvents]);
                                handleError(error);
                            });
                    }
                }

                // EVENT
                else if (showEvent) {
                    const event = {
                        IdtSta_Cal: 3,
                        Sta_CalDat: Sta_CalDat,
                        HdeEvt_Cal: HdeEvt_Cal,
                        IdtReePra_Usr: selected ? selected : defaultPraticien,
                        title: "Évènement : " + Rmk_Cal,
                        DatCal1_CalDat: moment(popupEventDate[0]).format("YYYY-MM-DD 00:00:00"),
                        DatCal2_CalDat: "0000-00-00 00:00:00",
                        start: startTime,
                        end: endTime,
                    }
                    const ev = { ...event, ...eventData };

                    Axios.post("/api/function.php?ajax=insertState", ev).then((res) => {
                        setFilteredEvents([...filteredEvents, ev]);
                    });

                    // GOOGLE AGENDA
                    if (googleCalendarSync.isSignedIn()) {
                        googleCalendarSync
                            .addEvent(primaryCalendarId, ev)
                            .then((newEvent) => {
                                newEvent.color = ev.color;
                                setMyEvents((oldEvents) => [...oldEvents, newEvent]);
                                setToastMessage('Event created in "' + calendarData[primaryCalendarId].name + '" calendar');
                                setToastOpen(true);
                            })
                            .catch((error) => {
                                setMyEvents((oldEvents) => [...oldEvents]);
                                handleError(error);
                            });
                    }
                }

                // VACATION
                else if (showVacation) {
                    const event = {
                        IdtSta_Cal: 4,
                        Sta_CalDat: Sta_CalDat,
                        title: "Vacances : " + Rmk_Cal,
                        DatCal1_CalDat: moment(popupEventDate[0]).format("YYYY-MM-DD 00:00:00"),
                        DatCal2_CalDat: popupEventDate[1] ? moment(popupEventDate[1]).format("YYYY-MM-DD 00:00:00") : moment(popupEventDate[0]).format("YYYY-MM-DD 00:00:00"),
                        start: startTime,
                        end: endTime,
                    }
                    const ev = { ...event, ...eventData };

                    Axios.post("/api/function.php?ajax=insertState", ev).then((res) => {
                        setFilteredEvents([...filteredEvents, ev]);
                    });

                    // GOOGLE AGENDA
                    if (googleCalendarSync.isSignedIn()) {
                        googleCalendarSync
                            .addEvent(primaryCalendarId, ev)
                            .then((newEvent) => {
                                newEvent.color = ev.color;
                                setMyEvents((oldEvents) => [...oldEvents, newEvent]);
                                setToastMessage('Event created in "' + calendarData[primaryCalendarId].name + '" calendar');
                                setToastOpen(true);
                            })
                            .catch((error) => {
                                setMyEvents((oldEvents) => [...oldEvents]);
                                handleError(error);
                            });
                    }
                }

                // GENERAL INSERT
                else {
                    const event = {
                        Sta_CalDat: Sta_CalDat,
                        DatCal1_CalDat: moment(mySelectedDate).format("YYYY-MM-DD 00:00:00"),
                        DatCal2_CalDat: moment(mySelectedDate).format("YYYY-MM-DD 00:00:00"),
                        start: startTime,
                        end: endTime,
                    }
                    const ev = { ...eventData, ...event };

                    const insertTitle = { ...ev, title: 'Création du patient en cours ...' };
                    setFilteredEvents([...filteredEvents, insertTitle]);

                    Axios.get(`/api/recordNum.php?ajax=isPatientAllreadyExistInWebsurvey&lname=${LstNme_Pat}&fname=${FstNme_Pat}&optId=${IdtOpt_Cal}&intId=${IdtInt_Cal}`).then((res) => {
                        let reInsert = '';
                        if (Number(res.data) > 0) {
                            let confirmInsert = window.confirm("Ce patient semble déjà exister sur websurvey.fr, souhaitez vous l'ajouter à websurvey.fr malgé tout ?");
                            confirmInsert ? reInsert = 1 : reInsert = 0;
                        } else {
                            reInsert = 1;
                        }

                        const rec = {
                            title:
                                Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 553 ?
                                    `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=3&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                    : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 550 ?
                                        `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=2&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                        : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 545 && IdtInt_Cal == 2641 ?
                                            `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=97&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                            : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 659 ?
                                                `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=111&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 660 ?
                                                    `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=121&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                    : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 664 ?
                                                        `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=127&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                        : Mde_Pat == 'hospitalisation' && Number(res.data) > 0 && IdtOpt_Cal == 564 ?
                                                            `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=24&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                            : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 553 ?
                                                                `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=3&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 550 ?
                                                                    `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=2&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                    : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 545 && IdtInt_Cal == 2641 ?
                                                                        `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=97&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                        : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 659 ?
                                                                            `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=111&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                            : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 660 ?
                                                                                `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=121&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 664 ?
                                                                                    `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=127&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                    : Mde_Pat == 'ambulatoire' && Number(res.data) > 0 && IdtOpt_Cal == 564 ?
                                                                                        `<a target=_blank href=https://${websurveyServer}/index.php?cat1=answerList&action=selectSurvey&selectedIdt_Sur=24&searchIdt_Rec=${IdtRecWeb_Pat}>
                                <img src=https://weborganizer.fr/img/ws.png />
                            </a>
                            <img src=https://weborganizer.fr/img/ambulatoire.png /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                        : Mde_Pat == 'hospitalisation' ?
                                                                                            ` <img src=https://weborganizer.fr/img/hospitalisation.jpg /> 
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`

                                                                                            : ` <img src=https://weborganizer.fr/img/ambulatoire.png />
                            <span class=interventionTime>${Dur_Int}</span>
                            <b>${LstNme_Pat}</b> ${FstNme_Pat} - <i>${Nme_Opt}</i> / <i>${Nme_Int}</i>`
                        }
                        const record = { ...ev, ...rec };

                        if (IdtOpt_Cal == 553 || IdtOpt_Cal == 550 || IdtOpt_Cal == 545 || IdtOpt_Cal == 659 || IdtOpt_Cal == 660 || IdtOpt_Cal == 664 || IdtOpt_Cal == 564) {
                            Axios.put(`/api/function.php?ajax=insertRecord&reInsert=${reInsert}`, record).then((res) => {
                                if (res.data.length > 0) {
                                    Axios.post("/api/function.php?ajax=insertAgenda", record).then((ins) => {
                                        if (ins.data.length > 0) {
                                            setFilteredEvents([...filteredEvents, record]);
                                            snackbar({ message: 'Créé avec succès' });
                                            setTimeout(() => {
                                                if(record.DatCal1_CalDat != '') {
                                                    sessionStorage.setItem("reloading", "true");
                                                    sessionStorage.setItem("LstNme_Pat", LstNme_Pat);
                                                    sessionStorage.setItem("FstNme_Pat", FstNme_Pat);
                                                    sessionStorage.setItem("Ref_Date", record.DatCal1_CalDat);
                                                    window.location.reload(false); 
                                                }
                                            }, 1500);
                                        }
                                    });
                                }
                            })
                        } else {
                            Axios.post("/api/function.php?ajax=insertAgenda", record).then((res) => {
                                setFilteredEvents([...filteredEvents, record]);
                                snackbar({ message: 'Créé avec succès' });

                                setTimeout(() => {
                                    if(record.DatCal1_CalDat != '') {
                                        sessionStorage.setItem("reloading", "true");
                                        sessionStorage.setItem("LstNme_Pat", LstNme_Pat);
                                        sessionStorage.setItem("FstNme_Pat", FstNme_Pat);
                                        sessionStorage.setItem("Ref_Date", record.DatCal1_CalDat);
                                        sessionStorage.setItem("Ref_Date_Year", moment(record.DatCal1_CalDat).format("YYYY"));
                                        sessionStorage.setItem("Ref_Date_Month", moment(record.DatCal1_CalDat).format("MM"));
                                        window.location.reload(false); 
                                    }
                                }, 1500);
                            });
                        }

                        const cal = {
                            title: LstNme_Pat + ' ' + FstNme_Pat + ' - ' + Nme_Opt + '/' + Nme_Int
                        }
                        const agenda = { ...ev, ...cal };

                        // GOOGLE AGENDA
                        if (googleCalendarSync.isSignedIn()) {
                            googleCalendarSync
                                .addEvent(primaryCalendarId, agenda)
                                .then((newEvent) => {
                                    newEvent.color = agenda.color;
                                    setMyEvents((oldEvents) => [...oldEvents, newEvent]);
                                    setToastMessage('Event created in "' + calendarData[primaryCalendarId].name + '" calendar');
                                    setToastOpen(true);
                                })
                                .catch((error) => {
                                    setMyEvents((oldEvents) => [...oldEvents]);
                                    handleError(error);
                                });
                        }
                    })
                }
            }

            setOpen(false);
            setBtnOpen(false);
        }
    }, [calendarData, primaryCalendarId, handleError, confirmEvent, confirmOldEvent, isEdit, filteredEvents, eventData, startDateCal, endDateCal, Nme_Opt, Nme_Int, Dur_Int, TotDur_Int, StartEnd_Int, mySelectedDate, popupEventDate, Sta_CalDat, showDate, showAbsent, absentEvent, showEvent, eventEvent, showVacation, vacationEvent, selected, defaultPraticien, IdtInt_Cal, IdtOpt_Cal, IdtRecWeb_Pat, FstNme_Pat, LstNme_Pat, HdeEvt_Cal, Mde_Pat, Rmk_Cal, tempEvent]);


    // DELETE STATE
    const deleteState = () => {
        const event = {
            Sta_CalDat: Sta_CalDat,
            Idt_CalDat: tempEvent.Idt_CalDat,
            DelSta_Cal: 1
        }
        const ev = { ...event, ...eventData };

        const result = window.confirm('Voulez-vous le supprimer ?');
        if (result === true) {
            Axios.put(`/api/function.php/${tempEvent.Idt_CalDat}?ajax=deleteState`, ev).then((res) => {
                setTimeout(() => {
                    const index = filteredEvents.findIndex(x => x.Idt_CalDat === tempEvent.Idt_CalDat);
                    const newEventList = [...filteredEvents];

                    if (googleCalendarSync.isSignedIn()) {
                        setSyncConfirm(true);
                    } else {
                        newEventList.splice(index, 1, ev);
                        setFilteredEvents(newEventList);
                    }
                }, 500);
            });
            snackbar({
                message: 'Supprimé'
            });
            setOpen(false);
            setBtnOpen(false);
        } else {
            setOpen(false);
            setBtnOpen(false);
        }
    }

    const loadPopupForm = useCallback((event) => {
        setVacationEndDate(event.end);
        setDate([(moment(event.start).utc().add(2, 'hours').format('DD/MM/YYYY HH:mm:ss')), (moment(event.end).utc().add(2, 'hours').format('DD/MM/YYYY HH:mm:ss'))]);
        setAllDay(event.allDay || true);
        setStatus(event.Sta_CalDat || '');
        setLastName(event.LstNme_Pat || '');
        setFirstName(event.FstNme_Pat || '');
        setBirthDate(event.Dob_Pat || '');
        setMail(event.Ema_Pat || '');
        setPhone(event.Tel_Pat || '');
        setSexe(event.Sex_Pat);
        setHideEvent(event.HdeEvt_Cal);
        setCorresName1(event.IdtCrp1_Pat || '0');
        setCorresName2(event.IdtCrp2_Pat || '0');
        setMode(event.Mde_Pat);
        setOperationId(event.IdtOpt_Cal || '0');
        setInterventionId(event.IdtInt_Cal || '0');
        setCodeOpt(event.OpeCod_Cal);
        setNote(event.Nte_Cal || '');
        setRemark(event.Rmk_Cal || '');
        setCcam1(event.Ccm1_Cal || '');
        setCcam2(event.Ccm2_Cal || '');
        setDevis(event.Dev_Cal || '');
        setPayment(event.Pyt_Cal || '');
        setValidPayment(event.ValPyt_Cal);
        setStartDate(moment(event.startDateCal).utc().subtract(2, 'hours').format('YYYY-MM-DDTHH:mm:ssZ'))
        setEndDate(moment(event.endDateCal).utc().subtract(2, 'hours').format('YYYY-MM-DDTHH:mm:ssZ'))
    
        Axios.get(`/api/recordNum.php?ajax=isPatientAllreadyExistInWebsurvey&lname=${event.LstNme_Pat}&fname=${event.FstNme_Pat}&optId=${event.IdtOpt_Cal}&intId=${event.IdtInt_Cal}`).then((res) => {
            setWebSurveyNum(res.data || '0');
        })
    }, []);

    const allDayChange = useCallback((ev) => {
        setAllDay(ev.target.checked);
    }, []);

    const dateChange = useCallback((args) => {
        setDate(args.value);
    }, []);

    const dateChangePostponed = useCallback((args) => {
        setDate(args.value);
        Axios.get(`/api/ajax.php?ajax=getChangedDate&Idt_Cal=${tempEvent.Idt_Cal}`).then((res) => {
            if (res.data.length > 0) {
                setRemark("Date initiale : " + moment(res.data[0].DatCal1_CalDat).format("DD/MM/YYYY"))
            }
        })
    }, [tempEvent]);

    const statusChange = useCallback((ev) => {
        setStatus('cancelled');
    }, []);

    const firstNameChange = useCallback((ev) => {
        setFirstName(ev.target.value);
    }, []);

    const lastNameChange = useCallback((ev) => {
        setLastName(ev.target.value.toUpperCase());
    }, []);

    const dobChange = useCallback((ev) => {
        setBirthDate(ev.value);
    }, []);

    const mailChange = useCallback((ev) => {
        setMail(ev.target.value);
    }, []);

    const phoneChange = useCallback((ev) => {
        setPhone(ev.target.value);
    }, []);

    const sexSelected = useCallback((ev) => {
        setSexe(ev.target.value);
    }, []);

    const HdeEvt_CalChange = useCallback((ev) => {
        setHideEvent(ev.target.checked);
    }, []);

    const correspondantSelected1 = useCallback((ev) => {
        setCorresName1(ev.target.value);
    }, []);

    const correspondantSelected2 = useCallback((ev) => {
        setCorresName2(ev.target.value);
    }, []);

    const operationChange = useCallback((ev) => {
        setOperationId(ev.target.value);
    }, []);

    const interventionChange = useCallback((ev) => {
        setInterventionId(ev.target.value);
    }, []);

    const modeSelected = useCallback((ev) => {
        setMode(ev.target.value);
    }, []);

    const noteChange = useCallback((ev) => {
        setNote(ev.target.value);
    }, []);

    const remarkChange = useCallback((ev) => {
        setRemark(ev.target.value);
    }, []);

    const codeOptChange = useCallback((ev) => {
        setCodeOpt(ev.target.value);
    }, []);

    const ccam1Change = useCallback((ev) => {
        setCcam1(ev.target.value);
    }, []);

    const ccam2Change = useCallback((ev) => {
        setCcam2(ev.target.value);
    }, []);

    const paymentChange = useCallback((ev) => {
        setPayment(ev.target.value);
    }, []);

    const devisChange = useCallback((ev) => {
        setDevis(ev.target.value);
    }, []);

    const validPaymentChange = useCallback((ev) => {
        setValidPayment(ev.target.value);
    }, []);

    const onSelectedDateChange = useCallback((event) => {
        setSelectedDate(event.date);
    }, []);

    const onEventClick = useCallback((args) => {
        setEdit(true);
        setTempEvent({ ...args.event });
        loadPopupForm(args.event);
        setAnchor(args.domEvent.target);

        // Popup event buttons
        let absentEvent = $('.mbsc-calendar-label-text:contains("Absent")');
        if (absentEvent.on("click", function () {
            setBtnOpen(true);
            $(".eventEvent").hide();
            $(".vacationEvent").hide();
        }));

        let eventEvent = $('.mbsc-calendar-label-text:contains("Évènement")');
        if (eventEvent.on("click", function () {
            setBtnOpen(true);
            $(".absentEvent").hide();
            $(".vacationEvent").hide();
        }));

        let vacationEvent = $('.mbsc-calendar-label-text:contains("Vacances")');
        if (vacationEvent.on("click", function () {
            setBtnOpen(true);
            $(".absentEvent").hide();
            $(".eventEvent").hide();
        }));

        // Popup main event
        let confirmedEvent = $(".mbsc-calendar-label-text:has(b)");
        if (confirmedEvent.on("click", function () { setOpen(true); }));

        let postponedEvent = $('.mbsc-calendar-label-text:contains("Reporté")');
        if (postponedEvent.on("click", function () {
            setOpen(false);
            Axios.get(`/api/ajax.php?ajax=getPostponedEvent&IdtPat_Cal=${args.event.IdtPat_Cal}`).then((res) => {
                if (res.data.length > 0) {
                    window.location.href = `/?year=${moment(res.data[0].DatCal1_CalDat).format('YYYY')}&month=${moment(res.data[0].DatCal1_CalDat).format('MM')}&pra=${args.event.IdtPra_Cal}&lastname=${args.event.LstNme_Pat}&firstname=${args.event.FstNme_Pat}`
                }
            })
        }));

        let cancelledEvent = $('.mbsc-calendar-label-text:contains("Annulé")');
        if (cancelledEvent.on("click", function () { setOpen(false); }));

        // Responsive
        let responsive = window.matchMedia('(max-width: 766px)');
        if (responsive.matches) {
            setOpen(true);
        }

        if (googleCalendarSync.isSignedIn()) {
            setConfirmEvent(args.event);
            setConfirmOldEvent(args.event);
        }
        
    }, [loadPopupForm]);

    const onEventCreated = useCallback((args) => {
        setEdit(false);
        setTempEvent(args.event)
        loadPopupForm(args.event);
        setAnchor(args.target);
        setOpen(true);
    }, [loadPopupForm]);

    const onEventUpdated = useCallback((args) => {
        setEdit(false);
        setTempEvent(args.event)
        loadPopupForm(args.event);
        setAnchor(args.target);
        setOpen(true);
    }, [loadPopupForm]);

    const handleSyncConfirmClose = useCallback(() => {
        
        $("#root").before('<div style="position: absolute; top: 50%; left: 50%; font-size: 20px; text-align: center;">Loading ... <br><img src='+Loading+' alt="Loading" height="50" style="padding-top: 0.5rem;"></div>')
        $("#root").css('opacity', '0.5');
        $("#root").css('pointer-events', 'none');
        
        // GET TOTAL EVENTS OF MONTH
        const year_month = moment(mySelectedDate).format("YYYY-MM");
        const praticienId = selected ? selected : defaultPraticien;
        let getTotalEvents = 0;

        Axios.get(`/api/ajax.php?ajax=getCurrentEvents&day=${year_month}&usrDefPra=${praticienId}`).then((res) => {
            if(res.data.length > 0) {
                getTotalEvents = Number(res.data.length);
            }
        })

        // DELETE 
        let ge = 0;
        $.each((googleEvent), function( i, gevt ) {
            const event = {
                id: gevt.id,
                start: moment(gevt.start).format("YYYY-MM")
            }
            if(year_month == event.start) {
                ge++;
                googleCalendarSync
                .deleteEvent(primaryCalendarId, event)
                .then(() => {
                    console.log('deleted')
                })
                .catch(handleError);
            }
        })
        setTimeout(() => {
            doLoop();
        }, ge*500);

        // SYNC EVENTS
        function doLoop() {
            var myArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
            let countAdd = 0;

            myArray.forEach(function (value, index) {
                let day = year_month+'-'+('0'+value).slice(-2);
                Axios.get(`/api/ajax.php?ajax=getCurrentEvents&day=${day}&usrDefPra=${praticienId}`).then((res) => {
                    if (res.data.length > 0) {
                        let startTime = '';
                        let endTime = '';
                        
                        $.each((res.data), function( i, evt ) {
                            countAdd = countAdd+1;

                            let title = '';
                            if(evt.Nme_Int == null || evt.Nme_Int == 'null') {
                                title = evt.LstNme_Pat + ' ' + evt.FstNme_Pat + ' - ' + evt.Nme_Opt;
                            } else {
                                title = evt.LstNme_Pat + ' ' + evt.FstNme_Pat + ' - ' + evt.Nme_Opt + '/' + evt.Nme_Int;
                            }
                            
                            if(i == 0) {
                                startTime = moment(evt.DatCal1_CalDat).format("YYYY-MM-DDT07:00:00Z");
                                if (evt.Dur_Int > 0) {
                                    endTime = moment(startTime).add(evt.Dur_Int, 'minutes');
                                } else {
                                    endTime = moment(startTime).add(30, 'minutes');
                                }
                            } else {
                                startTime = moment(endTime).add(0, 'minutes');
                                if (evt.Dur_Int > 0) {
                                    endTime = moment(startTime).add(evt.Dur_Int, 'minutes');
                                } else {
                                    endTime = moment(startTime).add(30, 'minutes');
                                }
                            }
    
                            const ev = {
                                Idt_Cal: evt.Idt_Cal,
                                title: title,
                                start: startTime,
                                end: endTime
                            }
    
                            googleCalendarSync
                                .addEvent(primaryCalendarId, ev)
                                .then((newEvent) => {
                                    newEvent.color = ev.color;
                                    setMyEvents((oldEvents) => [...oldEvents, newEvent]);
                                    setToastMessage('Events are created in "' + calendarData[primaryCalendarId].name + '" calendar');
                                })
                                .catch((error) => {
                                    setMyEvents((oldEvents) => [...oldEvents]);
                                    handleError(error);
                                });

                        })  
                    }
                    setTimeout(() => {
                        if(countAdd == getTotalEvents) {
                            console.log(countAdd)
                            window.location.reload(false)
                        }
                    }, countAdd*1000); 
                })
            })
        }
        setSyncConfirm(false);
    }, [calendarData, primaryCalendarId, googleEvent, mySelectedDate, selected, defaultPraticien, handleError]);
    
    // Datepicker options
    const controls = useMemo(() => popupEventAllDay ? ['date'] : ['datetime'], [popupEventAllDay]);
    const respSetting = useMemo(() => popupEventAllDay ? {
        medium: {
            controls: ['calendar'],
            touchUi: false
        }
    } : {
        medium: {
            controls: ['calendar', 'time'],
            touchUi: false
        }
    }, [popupEventAllDay]);

    // Popup options
    const headerText = useMemo(() => isEdit ? 'Editer' : 'Ajouter', [isEdit]);
    const popupButtons = useMemo(() => {
        if (isEdit) {
            return [
                'close',
                {
                    handler: () => {
                        saveEvent()
                    },
                    keyCode: 'enter',
                    text: 'Valider',
                    cssClass: 'mbsc-popup-button-primary'
                }
            ];
        } else {
            return [
                'close',
                {
                    handler: () => {
                        saveEvent()
                    },
                    keyCode: 'enter',
                    text: 'Valider',
                    cssClass: 'mbsc-popup-button-primary'
                }
            ];
        }
    }, [isEdit, saveEvent]);

    const dateMode = () => {
        setShowVacation(false);
        setShowAbsent(false);
        setShowEvent(false);
        setShowDate(true);
        setOpen(false);
        setBtnOpen(true);
        Axios.put(`/api/function.php?ajax=openPostponedPopup&Idt_Usr=${Idt_Usr}`).then((res) => {
            setStatus('postponed');
        });
    }
    const absentMode = () => {
        setShowDate(false);
        setShowVacation(false);
        setShowEvent(false);
        setShowAbsent(!showAbsent);
        setOpen(false);
        setBtnOpen(true);
        setStatus('confirmed');
    }
    const eventMode = () => {
        setShowDate(false);
        setShowAbsent(false);
        setShowVacation(false);
        setShowEvent(!showEvent);
        setOpen(false);
        setBtnOpen(true);
        setStatus('confirmed');
    }
    const vacationMode = () => {
        setShowDate(false);
        setShowAbsent(false);
        setShowEvent(false);
        setShowVacation(!showVacation);
        setOpen(false);
        setBtnOpen(true);
        setStatus('confirmed');
    }

    const onClose = useCallback(() => {
        if (!isEdit) {
            setFilteredEvents([...filteredEvents]);
        }
        setOpen(false);
        setCalOpen(false);
        setBtnOpen(false);
        setShowDate(false);
        setShowAbsent(false);
        setShowEvent(false);
        setShowVacation(false);
        setWebSurveyNum('');
        setPhone('');
    }, [isEdit, filteredEvents]);

    const [openPicker, setOpenPicker] = useState(false);
    const show = () => {
        setOpenPicker(true);
    };
    const closePicker = () => {
        setOpenPicker(false);
    };


    /* ************************************ Width RDV START ************************************** */
    $(function () {
        setInterval(() => {
            $('.mbsc-calendar-table-active .mbsc-calendar-row .mbsc-calendar-cell:nth-child(1) .mbsc-calendar-labels').each(function () {
                $(this).addClass('firstDay')
            })
            $('.mbsc-calendar-table-active .mbsc-calendar-row .mbsc-calendar-cell:nth-child(2) .mbsc-calendar-labels').each(function () {
                $(this).addClass('secondDay')
            })
            $('.mbsc-calendar-table-active .mbsc-calendar-row .mbsc-calendar-cell:nth-child(3) .mbsc-calendar-labels').each(function () {
                $(this).addClass('thirdDay')
            })
            $('.mbsc-calendar-table-active .mbsc-calendar-row .mbsc-calendar-cell:nth-child(4) .mbsc-calendar-labels').each(function () {
                $(this).addClass('fourthDay')
            })
            $('.mbsc-calendar-table-active .mbsc-calendar-row .mbsc-calendar-cell:nth-child(5) .mbsc-calendar-labels').each(function () {
                $(this).addClass('fifthDay')
            })
            $('.mbsc-calendar-table-active .mbsc-calendar-row .mbsc-calendar-cell:nth-child(6) .mbsc-calendar-labels').each(function () {
                $(this).addClass('sixthDay')
            })
            $('.mbsc-calendar-table-active .mbsc-calendar-row .mbsc-calendar-cell:nth-child(7) .mbsc-calendar-labels').each(function () {
                $(this).addClass('seventhDay')
            })
        }, 500);

        setTimeout(() => {
            let days = ['firstDay', 'secondDay', 'thirdDay', 'fourthDay', 'fifthDay', 'sixthDay', 'seventhDay'];
            $.each(days, function( i, day ) {
                if($('.'+day+':empty').length >= 5) {
                    $('.'+day).parent().parent().parent().css('min-width', '1rem');
                    $('.'+day).parent().parent().parent().css('width', '1rem');
                } else {
                    var quotations = [];
                    $('.'+day).each(function() {
                        $('.'+day).css('width', 'fit-content');
                        $('.'+day).css('z-index', '0');
                        setTimeout(() => {
                            quotations.push($(this).width());
                            let maxWidth = Math.max.apply(null, quotations) - 100;
                            $('.'+day).parent().parent().parent().css('width', maxWidth);
                            $('.'+day).parent().parent().parent().css('min-width', maxWidth);
                        }, 500);
                    })
                }
            });
        }, 1000);
    })
    /* ************************************ Width RDV END ************************************** */


    /* ************************************ Height RDV START ************************************** */
    let totalDivHeight = 0;
    let childrenDiv = $(".mbsc-calendar-cell-inner.mbsc-calendar-day-inner .mbsc-calendar-labels");
    let gridDivHeight = $(".mbsc-calendar-cell.mbsc-calendar-day.mbsc-calendar-day-labels");
    let rowDivHeight = $(".mbsc-calendar-cell-inner.mbsc-calendar-day-inner");

    $(".mbsc-calendar-button-prev, .mbsc-calendar-button-next, .mbsc-calendar-button-today").click(function () {
        $(".mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-labels .mbsc-calendar-day-inner").css("min-height", "25rem");
    })

    // ROW 1
    $(function () {
        $('.mbsc-calendar-table-active .mbsc-calendar-row:nth-child(2)').each(function () {
            let childDiv = $(this).find(childrenDiv);
            totalDivHeight = Math.max.apply(null, childDiv.map(function () {
                return $(this).outerHeight() + 50;
            }).get());
            $(this).find(gridDivHeight).css("height", totalDivHeight);
            $(this).find(rowDivHeight).css("min-height", totalDivHeight);
        })
    });

    // ROW 2
    $(function () {
        $('.mbsc-calendar-table-active .mbsc-calendar-row:nth-child(3)').each(function () {
            let childDiv = $(this).find(childrenDiv);
            totalDivHeight = Math.max.apply(null, childDiv.map(function () {
                return $(this).outerHeight() + 50;
            }).get());
            $(this).find(gridDivHeight).css("height", totalDivHeight);
            $(this).find(rowDivHeight).css("min-height", totalDivHeight);
        })
    });

    // ROW 3
    $(function () {
        $('.mbsc-calendar-table-active .mbsc-calendar-row:nth-child(4)').each(function () {
            let childDiv = $(this).find(childrenDiv);
            totalDivHeight = Math.max.apply(null, childDiv.map(function () {
                return $(this).outerHeight() + 50;
            }).get());
            $(this).find(gridDivHeight).css("height", totalDivHeight);
            $(this).find(rowDivHeight).css("min-height", totalDivHeight);
        })
    });

    // ROW 4
    $(function () {
        $('.mbsc-calendar-table-active .mbsc-calendar-row:nth-child(5)').each(function () {
            let childDiv = $(this).find(childrenDiv);
            totalDivHeight = Math.max.apply(null, childDiv.map(function () {
                return $(this).outerHeight() + 50;
            }).get());
            $(this).find(gridDivHeight).css("height", totalDivHeight);
            $(this).find(rowDivHeight).css("min-height", totalDivHeight);
        })
    });

    // ROW 5
    $(function () {
        $('.mbsc-calendar-table-active .mbsc-calendar-row:nth-child(6)').each(function () {
            let childDiv = $(this).find(childrenDiv);
            totalDivHeight = Math.max.apply(null, childDiv.map(function () {
                return $(this).outerHeight() + 50;
            }).get());
            $(this).find(gridDivHeight).css("height", totalDivHeight);
            $(this).find(rowDivHeight).css("min-height", totalDivHeight);
        })
    });

    // ROW 6
    $(function () {
        $('.mbsc-calendar-table-active .mbsc-calendar-row:nth-child(7)').each(function () {
            let childDiv = $(this).find(childrenDiv);
            totalDivHeight = Math.max.apply(null, childDiv.map(function () {
                return $(this).outerHeight() + 50;
            }).get());
            $(this).find(gridDivHeight).css("height", totalDivHeight);
            $(this).find(rowDivHeight).css("min-height", totalDivHeight);
        })
    });
    /* ************************************ Height RDV END ************************************** */

    /* ************************************ jQuery ************************************** */
    $(function () {
        // Select Practiciens on Menu
        $("#selectedPraticien").appendTo(".navbar .navbar-nav");

        // Calendar Height
        let gridCalendar = $(".mbsc-calendar-table-active").height();
        $(".mbsc-calendar-scroll-wrapper>div").css("height", gridCalendar);

        // Cancelled Event Color
        $('.mbsc-calendar-label-text:contains("Annulé")').css("color", "gray");

        // Operation & Intervention Name
        let optName = $("#operationId option:selected").text();
        setNameOpt(optName);
        let intName = $("#interventionName option:selected").text();
        setNameInt(intName);

        let mainDiv = $(".mbsc-calendar-label-start.mbsc-calendar-label-end");
        // Hide Intervention for Absent, Event, Vacation, Postponed and Cancelled events
        absentEvent.each(function () { $(this).closest(mainDiv).addClass("divSecondary"); });
        eventEvent.each(function () { $(this).closest(mainDiv).addClass("divSecondary"); });
        vacationEvent.each(function () { $(this).closest(mainDiv).addClass("divSecondary"); });
        $('.mbsc-calendar-label-text:contains("Reporté")').each(function () { $(this).closest(mainDiv).addClass("divSecondary"); });
        $('.mbsc-calendar-label-text:contains("Annulé")').each(function () { $(this).closest(mainDiv).addClass("divSecondary"); });

        // Event Intervention Duration
        $(".intervention-data").remove();
        $('.mbsc-calendar-labels').each(function () {
            let eventLen;
            let intLen = $(this).find(mainDiv).length;
            let secLen = $(this).find(".divSecondary").length;
            intLen > secLen ? eventLen = intLen - secLen : eventLen = secLen - intLen;
            if (eventLen > 0) {
                let sum = 0;
                $(this).find(".interventionTime").each(function () {
                    sum += +$(this).text() || 0;
                });

                const timeConvert = (n) => {
                    let minutes = (n % 60).toString().padStart(2, '0');
                    let hours = (n - minutes) / 60
                    if (hours > 0 || minutes > 0) {
                        return ' / ' + hours + "h" + minutes
                    } else {
                        return ''
                    }
                }
                if (Number(timeConvert(sum)) != 0) {
                    $(this).closest(".mbsc-calendar-cell-inner").prepend('<span class="intervention-data">' + eventLen + ' ' + (eventLen === 1 ? 'intervention' : 'interventions') + timeConvert(sum) + '</span>')
                }
            }
        })

        // Total Intervention Duration
        let durationVal = $("#interventionId option:selected").text();
        let result = durationVal.substring(durationVal.length - 4);
        let duration = Number(result.replace(/[^0-9 ]/g, ""))
        setDurationInt(duration);

        // Search Bar Empty Text
        $('.searchBar').keyup(function () {
            $(this).val().length == 0 ? $('.searchedData').hide() : $('.searchedData').show();
        }).keyup();

        // Highlight selected patient
        if (patientLstName || patientFstName) {
            $('.mbsc-calendar-label-text').each(function () {
                if ($(this).is(`:contains("${patientLstName}")`) && $(this).is(`:contains("${patientFstName}")`)) {
                    $(this).css("background", "yellow");
                }
                if ($(this).find(".postponedEvent").is(`:contains("${patientLstName}")`) && $(this).find(".postponedEvent").is(`:contains("${patientFstName}")`)) {
                    $(this).css("background", "#fff");
                }
            })
        }
        if (patLstName || patFstName) {
            $('.mbsc-calendar-label-text').each(function () {
                if ($(this).is(`:contains("${patLstName}")`) && $(this).is(`:contains("${patFstName}")`)) {
                    $(this).css("background", "yellow");
                    sessionStorage.removeItem("FstNme_Pat");
                    sessionStorage.removeItem("LstNme_Pat");
                }
                if ($(this).find(".postponedEvent").is(`:contains("${patLstName}")`) && $(this).find(".postponedEvent").is(`:contains("${patFstName}")`)) {
                    $(this).css("background", "#fff");
                }
            })
        }

        // Remove url parameters
        var currURL = window.location.href;
        var url = (currURL.split(window.location.host)[1]).split("?")[0];
        window.history.pushState({}, document.title, url);

        // Popup event buttons
        if ($('.showEvent').length) {
            $('.absentEvent').hide();
            $('.vacationEvent').hide();
        }
        else if ($('.showAbsent').length) {
            $('.eventEvent').hide();
            $('.vacationEvent').hide();
        }
        else if ($('.showVacation').length) {
            $('.eventEvent').hide();
            $('.absentEvent').hide();
        }
        else if ($('.showDate').length) {
            $('.eventEvent').hide();
            $('.absentEvent').hide();
            $('.vacationEvent').hide();
        }
        else {
            return false;
        }
    });

    useEffect(() => {
        const onSignedIn = () => {
            setIsLoggedIn(true);
            googleCalendarSync
                .getCalendars()
                .then((calendars) => {
                    calendars.sort((c) => (c.primary ? -1 : 1));

                    const calData = {};
                    const primaryCalId = calendars[0].id;
                    
                    for (const c of calendars) {
                        calData[c.id] = { name: c.summary, color: c.backgroundColor, checked: c.id === primaryCalId };
                    }
                    if (primaryCalendarId !== primaryCalId) {
                        setCalendarIds([primaryCalId]);
                        setPrimaryCalendarId(primaryCalId);
                        setCalendarData(calData);
                        setLoading(true);
                    }

                    return googleCalendarSync.getEvents(
                        [primaryCalId],
                        startDate.current,
                        endDate.current
                    );
                })
            .then((events) => {
                // console.log(events)
                setTempEvent(events);
                setGoogleEvent(events);
                setLoading(false);
            })
            .catch(handleError);
        };

        const onSignedOut = () => {
            setCalendarIds([]);
            setCalendarData({});
            setIsLoggedIn(false);
            setTempEvent([]);
            setOpen(false);
        };

        googleCalendarSync.init({
            apiKey: 'AIzaSyBq_QsHTg9KxQfbxGMZfL5oehT0-jtA7n8',
            clientId: '325402350882-ba59ddvu2ll7jd56g46jbb7ro11c5u6i.apps.googleusercontent.com',
            discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
            scope: "https://www.googleapis.com/auth/calendar",
            onSignedIn: onSignedIn,
            onSignedOut: onSignedOut,
        });

    }, [handleError]);

    /* ************************************ RENDER CALENDAR ************************************** */
    return (
        
        <div id="agendaPage">

            {/* SEARCH BAR */}
            <Form className="container-fluid mt-3 mb-1" id="agendaSearchBar">
                <div className="row m-auto justify-content-center">
                    <div className="col-md-6 col-sm-12">
                        <FormControl
                            type="search"
                            placeholder="Rechercher par le nom ou le prénom du patient"
                            className="p-2 searchBar"
                            aria-label="Search"
                            onChange={searchData}
                            value={search}
                        />
                        <div className="col-12 searchedData">
                            <ul className="adminNameList">
                                {dataTable.map((data, index) => (
                                    <li key={index}>
                                        {data.optYear === "0000-00-00 00:00:00" ? null :
                                            data.lastname.toLowerCase().includes(search.toLowerCase()) || data.firstname.toLowerCase().includes(search.toLowerCase()) ?
                                                <a href={`/?year=${moment(data.optYear).format('YYYY')}&month=${moment(data.optYear).format('MM')}&pra=${data.praticien}&lastname=${data.lastname}&firstname=${data.firstname}`}>
                                                    <span>{data.lastname}</span>&nbsp;
                                                    <span>{data.firstname}</span>&nbsp;&nbsp;
                                                    {data.optType ? <span className='subInfo'>{data.optType} / &nbsp;</span> : null}
                                                    {data.intType ? <span className='subInfo'>{data.intType} / &nbsp;</span> : null}
                                                    <span className='subInfo'>{moment(data.optYear).format('DD/MM/YYYY')} / &nbsp;</span>
                                                    {data.praticienName ? <span className='subInfo praticienName'>{data.praticienName}</span> : null}
                                                </a>
                                                : null
                                        }
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Form>

            {/* PRATICIEN */}
            <div className="mbsc-form-group" id="selectedPraticien">
                <Select label="Agenda de " inputProps={{ placeholder: 'Sélectionner un praticien' }} data={accessPraticien ? accessPraticien : null} onChange={filter} value={selected ? selected : defaultPraticien ? defaultPraticien : null} />
            </div>


            {/* CALENDAR */}
            <Eventcalendar
                view={view}
                clickToCreate="double"
                data={filteredEvents}
                dragToCreate={false}
                dragToMove={false}
                dragToResize={false}
                showEventTooltip={false}
                renderHeader={customHeader}
                onPageLoading={onPageLoading}
                selectedDate={mySelectedDate}
                onSelectedDateChange={onSelectedDateChange}
                onEventClick={onEventClick}
                onEventCreated={onEventCreated}
                onEventUpdated={onEventUpdated}
                exclusiveEndDates={true}
            />

            {/* EVENT CALENDAR POPUP */}
            <Popup
                contentPadding={false}
                display="anchored"
                isOpen={isCalOpen}
                scrollLock={false}
                showOverlay={false}
                touchUi={false}
                width={400}
                onClose={onClose}
            >
                {/* <div className="mbsc-form-group-inset">
                    <div className="mbsc-form-group-title">My Calendars</div>
                    {myCalendars.map((cal) => (
                        <Switch label={cal.summary} key={cal.id} value={cal.id} checked={calendarData[cal.id].checked} onChange={toggleCalendar} />
                    ))}
                </div> */}

                <div className="mbsc-form-group-inset">
                    <Button className="mbsc-button-block" onClick={signOut}>
                        Sign out of my account
                    </Button>
                </div>
            </Popup>

            {/* EVENT BUTTON POPUP */}
            <Popup
                display="bottom"
                fullScreen={true}
                contentPadding={false}
                headerText={headerText}
                anchor={anchor}
                buttons={popupButtons}
                isOpen={isBtnOpen}
                onClose={onClose}
                responsive={responsivePopup}
                closeOnEsc={false}
                className={"eventButtonPopup"}
            >

                {/* CHANGE DATE */}
                {showDate ?
                    <div className={showDate ? 'showDate' : 'changeDateEvent'}>
                        <Input ref={dateStartRef} label="Changer la date" />
                        <Datepicker
                            select="range"
                            controls={controls}
                            touchUi={true}
                            startInput={dateStart}
                            showRangeLabels={false}
                            responsive={respSetting}
                            onChange={dateChangePostponed}
                            value={popupEventDate}
                        />
                        <Textarea label="Remarques" name="Rmk_Cal" value={Rmk_Cal} onChange={remarkChange} />
                    </div>
                    : null}

                {/* ABSENT DATE */}
                {showAbsent || absentEvent ?
                    <div className={showAbsent ? 'showAbsent' : 'absentEvent'}>
                        <Input ref={absentStartRef} label="Absent" />
                        <Datepicker
                            select="range"
                            controls={controls}
                            touchUi={true}
                            startInput={absentStart}
                            showRangeLabels={false}
                            responsive={respSetting}
                            onChange={dateChange}
                            value={popupEventDate}
                        />
                        <Textarea label="Remarques" name="Rmk_Cal" value={Rmk_Cal} onChange={remarkChange} />
                        {isEdit ? <Button className="btn btn-sm btn-danger d-flex mx-auto" onClick={deleteState}> Supprimer </Button> : null}
                    </div>
                    : null}

                {/* EVENT DATE */}
                {showEvent || eventEvent ?
                    <div className={showEvent ? 'showEvent' : 'eventEvent'}>
                        <Switch label="Evènement" ref={eventStartRef} checked={popupEventAllDay} onChange={allDayChange} />
                        {popupEventAllDay ?
                            <div className="event_date">
                                <Datepicker
                                    select="range"
                                    controls={controls}
                                    touchUi={true}
                                    startInput={eventStart}
                                    showRangeLabels={false}
                                    responsive={respSetting}
                                    onChange={dateChange}
                                    value={moment(mySelectedDate).format('DD/MM/YYYY 00:00:00')}
                                />
                            </div>
                            : null}
                        <Textarea label="Remarques" name="Rmk_Cal" value={Rmk_Cal} onChange={remarkChange} />

                        <Form.Group className="my-2 d-flex justify-content-center">
                            <Form.Check type="checkbox" id="HdeEvt_Cal" label="Cacher cet évènement" value="1" checked={HdeEvt_Cal == 1 ? 1 : 0} onChange={HdeEvt_CalChange} />
                        </Form.Group>

                        {isEdit ? <Button className="btn btn-sm btn-danger d-flex mx-auto" onClick={deleteState}> Supprimer </Button> : null}
                    </div>
                    : null}

                {/* VACATION DATE */}
                {showVacation || vacationEvent ?
                    <div className={showVacation ? 'showVacation' : 'vacationEvent'}>
                        <Input ref={startRef} label="From" />
                        <Input ref={endRef} label="To" />
                        <Datepicker
                            select="range"
                            value={[popupEventDate[0], vacationEndDate]}
                            controls={controls}
                            touchUi={true}
                            startInput={start}
                            endInput={end}
                            showRangeLabels={false}
                            responsive={respSetting}
                            onChange={dateChange}
                        />
                        <Textarea label="Remarques" name="Rmk_Cal" value={Rmk_Cal} onChange={remarkChange} />
                        {isEdit ? <Button className="btn btn-sm btn-danger d-flex mx-auto" onClick={deleteState}> Supprimer </Button> : null}
                    </div>
                    : null}
            </Popup>

            <Popup
                display="bottom"
                fullScreen={false}
                contentPadding={false}
                headerText={headerText}
                anchor={anchor}
                buttons={popupButtons}
                isOpen={isOpen}
                onClose={onClose}
                responsive={responsivePopup}
                closeOnEsc={false}
                className={"mainEventPopup"}
            >

                <Input label="Date" value={moment(mySelectedDate).format("DD/MM/YYYY")} readOnly />

                {/* EVENT BUTTONS */}
                <div className="eventButtons text-center">
                    <Button onClick={eventMode}> Evènement </Button>

                    {isEdit ?
                        <>
                            <Button onClick={dateMode}> Changer Date </Button>

                            {/* EVENT STATUS */}
                            <SegmentedGroup name="Sta_CalDat" onChange={statusChange} value={Sta_CalDat}>
                                <SegmentedItem value="cancelled" checked={Sta_CalDat === 'cancelled'}> {Sta_CalDat === 'cancelled' ? 'RDV Annulé' : 'Annuler'} </SegmentedItem>
                            </SegmentedGroup>
                        </>
                        : null}

                    <Button onClick={absentMode}> Absent </Button>
                    <Button onClick={vacationMode}> Vacances </Button>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        {/* PATIENT */}
                        <p className="form_title mb-2"> <strong> Patient: </strong></p>
                        <div className="mbsc-form-group">
                            <Input label="Nom" name="LstNme_Pat" value={LstNme_Pat} onChange={lastNameChange} />
                        </div>

                        <div className="mbsc-form-group">
                            <Input label="Prénom" name="FstNme_Pat" value={FstNme_Pat} onChange={firstNameChange} />
                        </div>

                        <div className="mbsc-form-group birthDate">
                            <Datepicker
                                label="Date de naissance"
                                placeholder="jj/mm/aaaa"
                                name="Dob_Pat"
                                max={moment(now).subtract(1, 'year').toDate()}
                                controls={['calendar']}
                                showOnClick={false}
                                showOnFocus={false}
                                isOpen={openPicker}
                                onClose={closePicker}
                                onChange={dobChange}
                                value={Dob_Pat ? Dob_Pat : null}
                            />
                            <Button onClick={show}> Choisir la date </Button>
                        </div>

                        <div className="mbsc-form-group">
                            <Input label="Email" name="Ema_Pat" type="mail" value={Ema_Pat} onChange={mailChange} />
                        </div>

                        <div className="mbsc-form-group">
                            <Input label="Téléphone" name="Tel_Pat" value={Tel_Pat != 0 ? Tel_Pat : null} onChange={phoneChange} />
                        </div>

                        <div className="mbsc-form-group radio_box">
                            <label className="mbsc-ios mbsc-ltr mbsc-label mbsc-label-inline mbsc-label-underline-inline"> Sexe </label>
                            <Radio name="Sex_Pat" value="F" checked={Sex_Pat === 'F'} onChange={sexSelected}> Femme </Radio>
                            <Radio name="Sex_Pat" value="H" checked={Sex_Pat === 'H'} onChange={sexSelected}> Homme </Radio>
                        </div>

                        {/* CORRESPONDANTS */}
                        <p className="form_title my-2"> <strong> Correspondants: </strong></p>
                        <div className="mbsc-form-group">
                            <Dropdown label="Correspondant 1" name="IdtCrp1_Pat" value={IdtCrp1_Pat} onChange={correspondantSelected1}>
                                <option value="0"> Aucune </option>
                                {correspondant.map((cpr, index) => (
                                    <option name={cpr.Idt_Crp} key={index} value={cpr.Idt_Crp}> {cpr.Nme_Crp} </option>
                                ))}
                            </Dropdown>
                            <Dropdown label="Correspondant 2" name="IdtCrp2_Pat" value={IdtCrp2_Pat} onChange={correspondantSelected2}>
                                <option value="0"> Aucune </option>
                                {correspondant.map((cpr, index) => (
                                    <option name={cpr.Idt_Crp} key={index} value={cpr.Idt_Crp}> {cpr.Nme_Crp} </option>
                                ))}
                            </Dropdown>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        {/* OPERATION */}
                        <p className="form_title mb-2"> <strong> Opération: </strong></p>
                        <div className="mbsc-form-group radio_box">
                            <label className="mbsc-ios mbsc-ltr mbsc-label mbsc-label-inline mbsc-label-underline-inline"> Mode </label>
                            <Radio name="Mde_Pat" value="ambulatoire" checked={Mde_Pat === 'ambulatoire'} onChange={modeSelected}> Ambulatoire </Radio>
                            <Radio name="Mde_Pat" value="hospitalisation" checked={Mde_Pat === 'hospitalisation'} onChange={modeSelected}> Hospitalisation </Radio>
                        </div>

                        <div className="mbsc-form-group">
                            <Dropdown label="Operation" id="operationId" value={IdtOpt_Cal} onChange={operationChange}>
                                <option value="0"> Aucune </option>
                                {operation.map((opt, index) => (
                                    <option name={opt.Nme_Opt} key={index} value={opt.Idt_Opt}> {opt.Nme_Opt} </option>
                                ))}
                            </Dropdown>
                        </div>

                        {/* WEB SURVEY */}
                        <div className="mbsc-form-group">
                            <Input label="N° Websurvey" id="recordNumber" name="webSurveyNum" defaultValue={IdtRecWeb_Pat} readOnly />
                        </div>

                        {/* INTERVENTION DUPLICATE */}
                        <div className="mbsc-form-group duplicateInt">
                            <Dropdown label="Intervention" id="interventionId" value={IdtInt_Cal} onChange={interventionChange}>
                                <option value="0"> Aucune </option>
                                {intervention.map((int, index) => (
                                    <option name={int.Nme_Int} key={index} value={int.Idt_Int}> {int.Nme_Int + ' $:' + int.Dur_Int} </option>
                                ))}
                            </Dropdown>
                        </div>

                        {/* INTERVENTION */}
                        <div className="mbsc-form-group intervention">
                            <Dropdown label="Intervention" id="interventionName" value={IdtInt_Cal} onChange={interventionChange}>
                                <option value="0"> Aucune </option>
                                {intervention.map((int, index) => (
                                    <option name={int.Nme_Int} key={index} value={int.Idt_Int}> {int.Nme_Int} </option>
                                ))}
                            </Dropdown>
                            <div className="mbsc-form-group durationVal">
                                <Dropdown label="Duree" value={IdtInt_Cal} disabled>
                                    <option value="0"> 0 </option>
                                    {intervention.map((int, index) => (
                                        <option name={int.Dur_Int} key={index} value={int.Idt_Int}> {int.Dur_Int} </option>
                                    ))}
                                </Dropdown>
                            </div>
                        </div>

                        {/* CCAM */}
                        <div className="mbsc-form-group">
                            <Input label="Ccam1" name="Ccm1_Cal" value={Ccm1_Cal} onChange={ccam1Change} />
                        </div>
                        <div className="mbsc-form-group">
                            <Input label="Ccam2" name="Ccm2_Cal" value={Ccm2_Cal} onChange={ccam2Change} />
                        </div>

                        {/* PAYMENT */}
                        <div className="mbsc-form-group">
                            <Input label="Devis (€)" name="Dev_Cal" value={Dev_Cal} onChange={devisChange} />
                        </div>
                        <div className="mbsc-form-group">
                            <Input label="Paiement (€)" name="Pyt_Cal" value={Pyt_Cal} onChange={paymentChange} />
                        </div>

                        <div className="mbsc-form-group radio_box">
                            <label className="mbsc-ios mbsc-ltr mbsc-label mbsc-label-inline mbsc-label-underline-inline"> Payé </label>
                            <Radio name="ValPyt_Cal" value="Oui" checked={ValPyt_Cal === 'Oui'} onChange={validPaymentChange}> Oui </Radio>
                            <Radio name="ValPyt_Cal" value="Non" checked={ValPyt_Cal === 'Non'} onChange={validPaymentChange}> Non </Radio>
                        </div>
                    </div>
                </div>

                <div className="my-2">
                    {/* NOTE & REMARK */}
                    <div className="mbsc-form-group">
                        <Textarea label="Notes" name="Nte_Cal" value={Nte_Cal} onChange={noteChange} />
                    </div>

                    <div className="mbsc-form-group radio_box radio_box_3">
                        <label className="mbsc-ios mbsc-ltr mbsc-label mbsc-label-inline mbsc-label-underline-inline"> Côté à opérer </label>
                        <Radio name="OpeCod_Cal" value="g" checked={OpeCod_Cal === 'g'} onChange={codeOptChange}> Gauche </Radio>
                        <Radio name="OpeCod_Cal" value="d" checked={OpeCod_Cal === 'd'} onChange={codeOptChange}> Droit </Radio>
                    </div>

                    <div className="mbsc-form-group">
                        <Textarea id="eventRemark" label="Remarques" name="Rmk_Cal" value={Rmk_Cal} onChange={remarkChange} />
                    </div>
                </div>

            </Popup>
            <Toast isOpen={isToastOpen} message={toastMessage} onClose={handleToastClose} />
            <Confirm
                isOpen={isSyncConfirm}
                title="Voulez-vous confirmer votre action ?"
                okText="Valider"
                onClose={handleSyncConfirmClose}
            />
        </div>
    )
}

export default Agenda;